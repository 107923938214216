import { toggleHamburger } from "./toggleHamburger";
import { initializeViewport } from "./initializeViewport";
toggleHamburger("js-hamburger", "js-spmenu", 1024);
initializeViewport();

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.querySelector(".js-button-pagetop");
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const newsItems = document.querySelectorAll(".p-top-news__item");
  const thumbnailContainer = document.querySelector(".p-top-news__thumbnail");

  // 初期状態として、data-selected-newsに対応するアイテムに_hoverクラスを追加
  function updateHoverClass() {
    const selectedIndex = thumbnailContainer
      ? thumbnailContainer.getAttribute("data-selected-news")
      : null;

    if (selectedIndex === null) return;

    newsItems.forEach((item, index) => {
      if (index == selectedIndex) {
        item.classList.add("_active");
      } else {
        item.classList.remove("_active");
      }
    });
  }

  newsItems.forEach((item, index) => {
    item.addEventListener("mouseover", function () {
      thumbnailContainer.setAttribute("data-selected-news", index);
      updateHoverClass();
    });

    item.addEventListener("focus", function () {
      thumbnailContainer.setAttribute("data-selected-news", index);
      updateHoverClass();
    });
  });

  // 初期状態の_hoverクラスの適用
  updateHoverClass();
});

document.addEventListener("DOMContentLoaded", function () {
  // アニメーション
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("_active");
        }
      });
    },
    {
      root: null,
      rootMargin: "0px 0px -20% 0px",
      threshold: 0,
    }
  );

  const elements = document.querySelectorAll(".js-animation");
  elements.forEach((element) => {
    observer.observe(element);

    if (
      element.getBoundingClientRect().top <= window.innerHeight &&
      element.getBoundingClientRect().bottom >= 0
    ) {
      element.classList.add("_active");
    }
  });
  // スムーススクロール
  var scrollLinks = document.querySelectorAll('a[href^="#"]');
  scrollLinks.forEach(function (link) {
    link.addEventListener("click", function (event) {
      event.preventDefault();

      var targetId = this.getAttribute("href").substring(1);
      var targetElement = document.getElementById(targetId);
      if (targetElement) {
        var offsetTop =
          targetElement.getBoundingClientRect().top + window.pageYOffset - 85;

        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    });
  });
});

gsap.registerPlugin(ScrollTrigger);

const services = document.querySelectorAll(".p-top-service__item");
const serviceHeading = document.querySelector(".p-top-service__heading");

let scrollTriggerInstance;

function initServices() {
  const serviceAnimation = gsap.timeline();
  let serviceHeight;

  function setupServices() {
    serviceAnimation.clear();
    serviceHeight = services[0].offsetHeight;
    services.forEach((service, index) => {
      if (index > 0) {
        gsap.set(service, { y: index * serviceHeight });
        serviceAnimation.to(
          service,
          { y: 0, duration: index * 0.5, ease: "none" },
          0
        );
      }
    });
  }

  function applyScrollTrigger() {
    setupServices();
    scrollTriggerInstance = ScrollTrigger.create({
      trigger: ".p-top-service__list",
      start: "top-=118 top",
      pin: true,
      end: () => `+=${services.length * serviceHeight}`,
      scrub: true,
      animation: serviceAnimation,
      invalidateOnRefresh: true,
    });

    ScrollTrigger.addEventListener("refreshInit", setupServices);
  }

  function killScrollTrigger() {
    if (scrollTriggerInstance) {
      scrollTriggerInstance.kill(); // 既存のScrollTriggerを解除
      scrollTriggerInstance = null; // インスタンスをクリア
      ScrollTrigger.removeEventListener("refreshInit", setupServices);
      gsap.set(services, { clearProps: "all" }); // アニメーションの影響をクリア
    }
  }

  function checkWidth() {
    if (window.innerWidth >= 1200) {
      if (!scrollTriggerInstance) {
        applyScrollTrigger(); // 768px以上の場合、ScrollTriggerを適用
      }
    } else {
      killScrollTrigger(); // 768px未満の場合、ScrollTriggerを無効化
    }
  }

  // 初回チェック
  checkWidth();

  // リサイズイベントで再チェック
  window.addEventListener("resize", () => {
    checkWidth();
    ScrollTrigger.refresh();
  });
}

if (services.length > 0 && serviceHeading) {
  initServices();
}

// ローディングアニメーション
class TextWrapper {
  constructor() {}

  wrapCharacterWithSpan(char) {
    return `<span>${char}</span>`;
  }

  // 指定された要素内のテキストを1文字ずつ<span>タグで囲むメソッド
  wrapAllTextWithSpans(selector) {
    const elements = document.querySelectorAll(selector); // 指定された要素を全て取得
    elements.forEach((element) => {
      // 取得した要素全てに対してループ処理
      const texts = element.textContent // 要素内のテキストを取得
        .split("") // 1文字ずつ分割
        .map((char) => this.wrapCharacterWithSpan(char)) // 各文字を<span>タグで囲む
        .join(""); // 文字列に戻す

      element.innerHTML = texts; // 要素内に<span>タグで囲まれたテキストを表示
    });
  }
}

class Main {
  constructor() {
    this._init();
  }
  _init() {
    const textWrapper = new TextWrapper();
    textWrapper.wrapAllTextWithSpans(".js-textSpan");
  }
}
new Main();

// 初回訪問かどうかを判定
if (!sessionStorage.getItem("visited")) {
  // 初回訪問の場合、splashTimelineとmainContentTimelineを実行
  sessionStorage.setItem("visited", "true"); // 訪問フラグを設定

  // 最初のタイムライン
  const splashTimeline = gsap.timeline({});
  splashTimeline
    .fromTo(
      ".s-splash-loading__main.js-textSpan > span",
      0.5,
      {
        opacity: 0,
        scale: 2,
        x: -10,
      },
      {
        opacity: 1,
        scale: 1,
        x: 0,
        ease: "power3.out",
        stagger: {
          amount: 0.3,
        },
      }
    )
    .fromTo(
      ".s-splash-loading__sub.js-textSpan > span",
      0.4,
      {
        opacity: 0,
        scale: 0,
        x: -10,
      },
      {
        opacity: 1,
        scale: 1,
        x: 0,
        ease: "power3.out",
        stagger: {
          amount: 0.3,
        },
      }
    )
    .fromTo(
      ".s-splash-loading__logo",
      0.4,
      {
        x: -30,
        opacity: 0,
      },
      {
        x: 0,
        ease: "power3.out",
        opacity: 1,
        delay: 0.5,
      }
    )
    .fromTo(
      ".s-splash-loading__body",
      0.4,
      {
        x: 0,
        opacity: 1,
      },
      {
        x: 30,
        ease: "power3.out",
        opacity: 0,
        delay: 0.5,
      },
      "-=0.9"
    )
    .fromTo(
      ".s-splash-loading",
      1,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "power3.out",
        delay: 0.7,
      }
    )
    .add(() => {
      const splashLoading = document.querySelector(".s-splash-loading");
      if (splashLoading) {
        splashLoading.style.display = "none";
      }
    });

  // splashTimelineの後にmainContentTimelineを開始
  splashTimeline.add(() => {
    startMainContentTimeline(0); // 初回訪問時は遅延なしで開始
  });
} else {
  // 2回目以降の訪問の場合、mainContentTimelineのみを遅延付きで実行
  startMainContentTimeline(0.5); // 2回目以降の訪問時は1秒の遅延
}

function startMainContentTimeline(delay) {
  const mainContentTimeline = gsap.timeline({
    delay: delay, // 指定された遅延時間を設定
  });

  mainContentTimeline
    .fromTo(
      ".p-top-mv__parts.js-textSpan > span",
      0.4,
      {
        y: "100%",
      },
      {
        y: 0,
        ease: "power2.out",
        stagger: {
          amount: 0.3,
        },
      }
    )
    .fromTo(
      ".p-top-mv__sub.js-textSpan > span",
      0.4,
      {
        y: "100%",
      },
      {
        y: 0,
        ease: "power2.out",
        stagger: {
          amount: 0.3,
        },
      },
      "-=0.2"
    );
}

gsap.utils.toArray(".js-gsap-fade-in-up").forEach((parentElement) => {
  gsap.fromTo(
    parentElement.querySelectorAll(".js-gsap-fade-in-up__child"), // 子要素を選択
    {
      opacity: 0,
      y: 30,
    },
    {
      opacity: 1,
      y: 0,
      duration: 1.0, // 各アニメーションの時間
      ease: "power3.out",
      stagger: 0.3, // 各要素のアニメーション間の遅延
      scrollTrigger: {
        trigger: parentElement, // トリガーとなる要素
        start: "top 70%", // アニメーションを開始する位置（画面の80%位置で開始）
        end: "bottom 20%", // アニメーションを終了する位置
        toggleActions: "play none none none", // スクロールアクションの設定（再生のみ）
      },
    }
  );
});

gsap.utils.toArray(".js-gsap-swipe-up").forEach((parentElement) => {
  gsap.fromTo(
    parentElement.querySelectorAll(".js-gsap-swipe-up__child"), // 子要素を選択
    {
      y: "100%", // 初期のY位置（下から）
    },
    {
      y: 0, // 終了時のY位置
      duration: 1.0, // 各アニメーションの時間
      ease: "power2.out", // イージング
      stagger: 0.3, // 各要素のアニメーション間の遅延
      scrollTrigger: {
        trigger: parentElement, // 親要素がスクロールトリガーの対象
        start: "top 80%", // スクロール位置の開始点
        end: "top 50%", // スクロール位置の終了点
      },
    }
  );
});

document
  .querySelectorAll(
    ".s-splash-loading__main.js-textSpan > span, .s-splash-loading__sub.js-textSpan > span, .s-splash-loading__logo, .s-splash-loading__body, .s-splash-loading"
  )
  .forEach((el) => {
    el.classList.add("gsap-in-view");
  });
